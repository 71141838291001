body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.content-enter {
  opacity: 0;
}
.content-enter-active {
  opacity: 1;
  transition: opacity 1000ms;
}
.content-exit {
  opacity: 1;
}
.content-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}

.table-cell > .suscription-paid,
.table-cell > .payment-status {
  border-radius: 16px;
  color: #FFFFFF;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
}

.table-cell > .suscription-paid.suscription-paid--initialized {
  background-color: #4772FA;
}

.table-cell > .suscription-paid.suscription-paid--cancelled,
.table-cell > .payment-status.payment-status--declined {
  background-color: #ED4B4B;
}

.table-cell > .suscription-paid.suscription-paid--finished,
.table-cell > .payment-status.payment-status--pending {
  background-color: #FEC703;
}

.table-cell > .suscription-paid.suscription-paid--activated,
.table-cell > .payment-status.payment-status--paid {
  background-color: #0CC863;
}
